var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                    },
                    [
                      _c("img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/images/pages/register.jpg"),
                          alt: "register",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                    },
                    [
                      _vm.registrationDone
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "px-12 pt-12 pb-12 register-tabs-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-card__title mb-4" },
                                [
                                  _c("h4", { staticClass: "mb-4" }, [
                                    _vm._v("Successful Registration"),
                                  ]),
                                  _c(
                                    "tp-cardtext",
                                    { attrs: { "min-height": 150 } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "Thank you for your interest in Travelify. One of our sales team will be in touch shortly with more information."
                                        ),
                                      ]),
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "mt-6",
                                          attrs: {
                                            type: "border",
                                            to: "/login",
                                          },
                                        },
                                        [_vm._v("Go to login page")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.registrationDone
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "px-12 pt-12 pb-12 register-tabs-container",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-card__title mb-4" },
                                [
                                  _c("h4", { staticClass: "mb-4" }, [
                                    _vm._v(
                                      "Travelify :: Register Your Interest"
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "Fill the form below to register your company interest in Travelify and we will get back to you shortly to discuss your requirements."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "clearfix" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "blur",
                                      "label-placeholder": "First Name",
                                      name: "firstName",
                                      placeholder: "First Name",
                                    },
                                    model: {
                                      value: _vm.firstName,
                                      callback: function ($$v) {
                                        _vm.firstName = $$v
                                      },
                                      expression: "firstName",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("firstName")
                                            ? "First Name is required"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "blur",
                                      "label-placeholder": "Last Name",
                                      name: "lastName",
                                      placeholder: "Last Name",
                                    },
                                    model: {
                                      value: _vm.lastName,
                                      callback: function ($$v) {
                                        _vm.lastName = $$v
                                      },
                                      expression: "lastName",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("lastName")
                                            ? "Last Name is required"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "blur",
                                      name: "email",
                                      type: "email",
                                      "label-placeholder": "Email",
                                      placeholder: "Email",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("email")
                                            ? "Email is required"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-full mt-6" },
                                    [
                                      _c("VuePhoneNumberInput", {
                                        attrs: {
                                          "default-country-code": "GB",
                                          "preferred-countries": [
                                            "GB",
                                            "US",
                                            "FR",
                                            "BE",
                                            "DE",
                                          ],
                                          "no-flags": "",
                                          "show-code-on-list": "",
                                        },
                                        on: {
                                          update: function ($event) {
                                            _vm.phoneNumberResult = JSON.parse(
                                              JSON.stringify($event)
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.phoneNumber,
                                          callback: function ($$v) {
                                            _vm.phoneNumber = $$v
                                          },
                                          expression: "phoneNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "blur",
                                      "label-placeholder": "Company Name",
                                      name: "companyName",
                                      placeholder: "Company Name",
                                    },
                                    model: {
                                      value: _vm.companyName,
                                      callback: function ($$v) {
                                        _vm.companyName = $$v
                                      },
                                      expression: "companyName",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("companyName")
                                            ? "Company Name is required"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "blur",
                                      "label-placeholder": "Role in Company",
                                      name: "companyRole",
                                      placeholder: "Role in Company",
                                    },
                                    model: {
                                      value: _vm.companyRole,
                                      callback: function ($$v) {
                                        _vm.companyRole = $$v
                                      },
                                      expression: "companyRole",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("companyRole")
                                            ? "Company Role is required"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("ValidationProvider", {
                                    attrs: { rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-select", {
                                                staticClass: "w-full mt-6",
                                                attrs: {
                                                  placeholder: "Company Type",
                                                  name: "companyType",
                                                  options:
                                                    _vm.companyTypeOptions,
                                                },
                                                model: {
                                                  value: _vm.companyType,
                                                  callback: function ($$v) {
                                                    _vm.companyType = $$v
                                                  },
                                                  expression: "companyType",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-danger text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0]
                                                        ? "Company Type is required"
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2825128507
                                    ),
                                  }),
                                  _c("ValidationProvider", {
                                    attrs: { rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-select", {
                                                staticClass: "w-full mt-6",
                                                attrs: {
                                                  placeholder: "Country",
                                                  name: "countryCode",
                                                  options: _vm.countryOptions,
                                                },
                                                model: {
                                                  value: _vm.countryCode,
                                                  callback: function ($$v) {
                                                    _vm.countryCode = $$v
                                                  },
                                                  expression: "countryCode",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-danger text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      errors[0]
                                                        ? "Country is required"
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3312561772
                                    ),
                                  }),
                                  _c("vs-textarea", {
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "label-placeholder": "Message",
                                      name: "message",
                                      placeholder: "Message",
                                      counter: "256",
                                      maxlength: "256",
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.message,
                                      callback: function ($$v) {
                                        _vm.message = $$v
                                      },
                                      expression: "message",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "w-full mt-6" },
                                    [
                                      _c("vue-recaptcha", {
                                        ref: "recaptcha",
                                        attrs: {
                                          sitekey: _vm.recaptchaSiteKey,
                                        },
                                        on: { verify: _vm.onVerify },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vs-checkbox", { staticClass: "mt-6" }, [
                                    _vm._v("I accept the terms & conditions."),
                                  ]),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "mt-6",
                                      attrs: { type: "border", to: "/login" },
                                    },
                                    [_vm._v("Login")]
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "float-right mt-6",
                                      attrs: { disabled: !_vm.validateForm },
                                      on: { click: _vm.registerCompany },
                                    },
                                    [
                                      _vm._v(
                                        "Register Interest\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("vs-divider", [_vm._v("EXISTING COMPANIES")]),
                              _c("div", [
                                _vm._v(
                                  "\n                If your company already has an account with Travelify and you need a login, please speak to your company administrator to add you as a new user. This form is for new companies to register their interest in the product.\n              "
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }